export default function IconEmail(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10" id="Envelope-Mail--Streamline-Micro">
      <desc>{'Envelope Mail Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.5 1h-7a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h7a1 1 0 0 0 1 -1V2a1 1 0 0 0 -1 -1Z"
        strokeWidth={1}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m0.5 2 3.855 3.253a1 1 0 0 0 1.29 0L9.5 2"
        strokeWidth={1}
      />
    </svg>
  );
}
